import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all products
export const countProducts = () => {
  return authClient().get(`${API}/product/count`)
}

// Get all products
export const getProducts = () => {
  return authClient().get(`${API}/product`)
}

// Get product by id
export const getProductById = (id) => {
  return authClient().get(`${API}/product/${id}`)
}

// Delete product
export const deleteProduct = (id) => {
  return authClient().delete(`${API}/product/${id}`)
}

// Update product
export const updateProduct = async (id, product, images) => {
    if (images) {
      const imagesURLs = await uploadFilesGetLinks(images, 'products')
      product.imagesURLs = imagesURLs
    }
    return authClient().put(`${API}/product/${id}`, product)
}

// Save product
export const postProduct = async (product, images) => {
    if (images) {
      const imagesURLs = await uploadFilesGetLinks(images, 'products')
      product.imagesURLs = imagesURLs
    }
    return authClient().post(`${API}/product`, product)
}
