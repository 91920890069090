import { authClient, API } from "../index";
import { uploadSingleFile } from "../files";

// Count all carrousels
export const countCarrousel = () => {
  return authClient().get(`${API}/carrousel/count`);
};

// Get all carrousels
export const getCarrousels = () => {
  return authClient().get(`${API}/carrousel`);
};

// Get carrousel by id
export const getCarrouselById = (id) => {
  return authClient().get(`${API}/carrousel/${id}`);
};

// Delete carrousel
export const deleteCarrousel = (id) => {
  return authClient().delete(`${API}/carrousel/${id}`);
};

// Update carrousel
export const updateCarrousel = async (id, carrousel, image) => {
  if (image) {
    const response = await uploadSingleFile(image, "carrousels");
    carrousel.imageURL = response.data.fileURL;
  }
  return authClient().put(`${API}/carrousel/${id}`, carrousel);
};

// Save carrousel
export const postCarrousel = async (carrousel, image) => {
  console.log("IMAGE: ", image, "CARROUSEL: ", carrousel);
  if (image) {
    const response = await uploadSingleFile(image, "carrousels");
    carrousel.imageURL = response.data.fileURL;
  }
  return authClient().post(`${API}/carrousel`, carrousel);
};

// Change order carrousel
export const changeOrderCarrousel = async (id, order) => {
  return authClient().put(`${API}/carrousel/change-order/${id}?order=${order}`);
};
