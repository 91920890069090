import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deletePress, getPressById, postPress, updatePress } from '../../../../api/press'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { SERVER_URL } from "../../../../api"
import { alertError, alertSuccess } from '../../../../utils/logger'
import { buttonsStyle } from '../../../components/tables/table'
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import Editor from '../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

function getEmptyPress() {
  return {
    title: {},
    subtitle: {},
    content: {},
    filesURLs: [],
    link: '',
    active: true,
    date: null
  }
}

export default function EditPressPage() {
  const [press, setPress] = useState(getEmptyPress())
	const [selectedFiles, setSelectedFiles] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const pressId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!pressId) {
      disableLoadingData()
      return
    }
    getPressById(pressId).then(res => {
      if (res.status === 200) {
        setPress(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get press.' })
      history.push('/presses')
    })
  }, [pressId, disableLoadingData, history])

  function savePress() {
    if (!pressId) {
      postPress(press, selectedFiles).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Press saved successfully.' })
          history.push('/presses')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save press.' })
      })
    } else {
      updatePress(pressId, press, selectedFiles).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/presses')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!press[element]) press[element] = {}
      let newText = press[element]
      newText[lang] = event.target.value
      setPress({ ...press, [element]: newText })
    } else setPress({ ...press, [element]: event.target.value })
  }

  const handleChangeEditor = (element, lang, value) => {
    if (lang) {
      if (value === ' ') return
      if (!press[element]) press[element] = {}
      let newText = press[element]
      newText[lang] = value
      setPress({ ...press, [element]: newText })
    } else setPress({ ...press, [element]: value })
  }

  const handleDeleteFile = (index) => {
    // TODO: DELETE FILES FROM SERVER
    let newFiles = [...press.filesURLs]
    /*const deletedFile =*/ newFiles.splice(index, 1)
    setPress({ ...press, filesURLs: newFiles })
    if (selectedFiles && selectedFiles[index]) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key !== index) {
          if (key > index) {
            _selectedFiles[key - 1] = value
          } else _selectedFiles[key] = value
        } 
      }
      if (!Object.keys(_selectedFiles).length) _selectedFiles = null
      setSelectedFiles(_selectedFiles)
    } /*else setDeletedFiles(deletedFiles ? deletedFiles.concat(deletedFile) : [deletedFile])*/
  }

  const handleMoveFile = (index, newIndex) => {
    const aux = press.filesURLs[index]
    let files = [...press.filesURLs]
    files.splice(index, 1, files[newIndex])
    files.splice(newIndex, 1, aux)
    setPress({ ...press, filesURLs: files })
    if (selectedFiles && (selectedFiles[index] || selectedFiles[newIndex])) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key === index.toString()) _selectedFiles[newIndex] = value
        else if (key === newIndex.toString()) _selectedFiles[index] = value
        else _selectedFiles[key] = value
      }
      setSelectedFiles(_selectedFiles)
    }
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`title`}
					label="Title"
          value={(press.title && press.title[lang]) || ''}
          onChange={handleChange('title', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
				<TextField
					id={`subtitle`}
					label="Subtitle"
					value={(press.subtitle && press.subtitle[lang]) || ''}
					onChange={handleChange('subtitle', lang)}
					InputLabelProps={{
						shrink: true
					}}
					margin="normal"
					variant="outlined"
				/>
        <Editor
          body={(press.content && press.content[lang]) || ''}
          setBody={new_body => handleChangeEditor('content', lang, new_body)}
          className='max-height'
          lang={lang}
          placeholder={'Enter press content here...'}
        />
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit press'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <TextField
            id={`link`}
            label="Link"
            value={press.link}
            onChange={handleChange('link')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="date"
            label="Date"
            type="date"
            value={press.date && press.date.includes('T') ? press.date.split('T')[0] : press.date}
            onChange={handleChange('date')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
        </CardBody>
        <CardBody>
          {press.filesURLs?.map((_, index) => {
            return (
              <>
                <label htmlFor={"upload-file" + index}>
                  <input
                    style={{ display: 'none' }}
                    id={"upload-file" + index}
                    name={"upload-file" + index}
                    type="file"
                    onChange={(e) => {
                      setSelectedFiles({ ...selectedFiles, [index]: e.target.files[0] })
                    }}
                  />
                  <Button
                    style={{ marginRight: '15px' }}
                    color="secondary"
                    component="span"
                    variant="outlined">
                    {((selectedFiles && selectedFiles[index]) || press.filesURLs[index] !== '') ? 'Change file ' + (index + 1) : 'Upload file ' + (index + 1)}
                  </Button>
                </label>
                {((selectedFiles && selectedFiles[index]) || press.filesURLs[index] !== '') &&
                  <>
                    <Tooltip title={'Preview file ' + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(index)}
                        style={buttonsStyle}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={'Preview file ' + (index + 1)}
                      open={openPreviewDialog === index}
                      setOpen={setOpenPreviewDialog}
                      src={(selectedFiles && selectedFiles[index]) ? URL.createObjectURL(selectedFiles[index]) : `${SERVER_URL}/${press.filesURLs[index]}`}
                    />
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move up">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() => handleMoveFile(index, index - 1)}>
                        <ArrowUpward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        size="small"
                        disabled={!(index < press.filesURLs.length - 1 && ((selectedFiles && selectedFiles[index + 1]) || press.filesURLs[index + 1] !== ''))}
                        style={{...buttonsStyle, marginRight: '1em' }}
                        onClick={() => handleMoveFile(index, index + 1)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <span>
                      {selectedFiles && selectedFiles[index] ? selectedFiles[index]?.name : (press.filesURLs[index] !== '' ? press.filesURLs[index].split(/-(.*)/s)[1] : '')}
                    </span>
                    <br />
                  </>
                }
              </>
            )
          })}
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setPress({ ...press, filesURLs: press.filesURLs.concat('') })}
          >
            Add file
          </Button>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={press.active} onChange={() => setPress({ ...press, active: !press.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/presses')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => savePress()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save press
      </Button>
      {pressId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete press
          </Button>
        </MuiThemeProvider>
      </>}
      <ConfirmDialog
        title={'Are you sure you want to delete this press?'}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deletePress(pressId).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: 'Deleted!', customMessage: 'Press deleted successfully' })
              history.push('/presses')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: 'Could not delete press.' })
          })
        }}
      />
    </>
  );
}
