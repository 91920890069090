import { authClient, API } from "../index";
import { uploadFilesGetLinks, uploadSingleFile } from "../files";

// Count all gallerys
export const countGallerys = () => {
  return authClient().get(`${API}/gallery/count`);
};

// Get all gallerys
export const getGallerys = () => {
  return authClient().get(`${API}/gallery`);
};

// Get gallery by id
export const getGalleryById = (id) => {
  return authClient().get(`${API}/gallery/${id}`);
};

// Get gallery by user id
export const getGalleryByUserId = (id) => {
  return authClient().get(`${API}/gallery/user/${id}`);
};

// Delete gallery
export const deleteGallery = (id) => {
  return authClient().delete(`${API}/gallery/${id}`);
};

// Update gallery
// export const updateGallery = async (id, gallery, files) => {
//   if (files) {
//     const filesURLs = await uploadFilesGetLinks(files, 'gallery')
//     gallery.filesURLs = filesURLs
//   }
//   return authClient().put(`${API}/gallery/${id}`, gallery)
// }

export const updateGallery = async (id, gallery, selectedFiles) => {
  let updatedGallery = { ...gallery };
  let newFilesURLs = { ...gallery.filesURLs };

  for (const index in selectedFiles) {
    const file = selectedFiles[index];
    if (file && file instanceof File) {
      const response = await uploadSingleFile(file, "gallery");
      if (response && response.status === 200 && response.data?.fileURL) {
        newFilesURLs[index] = response.data.fileURL;
      }
    } else {
      newFilesURLs[index] = file;
    }
  }

  updatedGallery.filesURLs = Object.values(newFilesURLs);
  return authClient().put(`${API}/gallery/${id}`, updatedGallery);
};

// Save gallery
export const postGallery = async (gallery, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, "gallery");
    gallery.filesURLs = filesURLs;
  }
  return authClient().post(`${API}/gallery`, gallery);
};
