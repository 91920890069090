import { authClient, API } from '../index'
import { uploadSingleFile } from "../files"

// Count all management teams
export const countManagementTeam = () => {
  return authClient().get(`${API}/management-team/count`)
}

// Get all management teams
export const getManagementTeam = () => {
  return authClient().get(`${API}/management-team`)
}

// Get management team by id
export const getManagementTeamById = (id) => {
  return authClient().get(`${API}/management-team/${id}`)
}

// Delete management team
export const deleteManagementTeam = (id) => {
  return authClient().delete(`${API}/management-team/${id}`)
}

// Update management team
export const updateManagementTeam = async (id, managementTeam, image) => {
    if (image) {
      const response = await uploadSingleFile(image, 'management-team')
      managementTeam.imageURL = response.data.fileURL
    }
    return authClient().put(`${API}/management-team/${id}`, managementTeam)
}

// Save management team
export const postManagementTeam = async (managementTeam, image) => {
    if (image) {
      const response = await uploadSingleFile(image, 'management-team')
      managementTeam.imageURL = response.data.fileURL
    }
    return authClient().post(`${API}/management-team`, managementTeam)
}

// Change order management team
export const changeOrderManagementTeam = async (id, order) => {
  return authClient().put(`${API}/management-team/change-order/${id}?order=${order}`)
}