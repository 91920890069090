import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all presses
export const countPresses = () => {
  return authClient().get(`${API}/press/count`)
}

// Get all presses
export const getPresses = () => {
  return authClient().get(`${API}/press`)
}

// Get press by id
export const getPressById = (id) => {
  return authClient().get(`${API}/press/${id}`)
}

// Get press by user id
export const getPressByUserId = (id) => {
  return authClient().get(`${API}/press/user/${id}`)
}

// Delete press
export const deletePress = (id) => {
  return authClient().delete(`${API}/press/${id}`)
}

// Update press
export const updatePress = async (id, press, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'press')
    press.filesURLs = filesURLs
  }
  return authClient().put(`${API}/press/${id}`, press)
}

// Save press
export const postPress = async (press, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'press')
    press.filesURLs = filesURLs
  }
  return authClient().post(`${API}/press`, press)
}
