import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
  booleanFormatter,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getCarrousels,
  deleteCarrousel,
  changeOrderCarrousel,
} from "../../../../api/carrousel";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";

function getData(carrousels) {
  let data = [];
  for (let i = 0; i < carrousels.length; ++i) {
    const elem = {};
    elem.name = carrousels[i].title?.en;
    elem.subtitle = carrousels[i].subtitle?.en;
    elem.createdAt = carrousels[i].createdAt;
    elem.active = carrousels[i].active;
    elem.order = carrousels[i].order;
    elem.id = carrousels[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function CarrouselPage() {
  const [data, setData] = useState([]);
  const [carrouselId, setCarrouselId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-carrousel/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setCarrouselId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  function handleSort(row1, row2, order1, order2) {
    changeOrderCarrousel(row1.id, order1).then((res) => {
      if (res.status === 200) {
        changeOrderCarrousel(row2.id, order2).then((resp) => {
          if (resp.status === 200) {
            setRefresh(true);
          }
        });
      }
    });
  }

  function sortFormatter(cell) {
    if (!data.length) return <></>;
    const index = data.findIndex((item) => item.order === cell);
    const indexPrevious = data.findIndex((item) => item.order === cell - 1);
    const indexNext = data.findIndex((item) => item.order === cell + 1);
    return (
      <>
        {data[index].order === 1 ? (
          <Button style={buttonsStyle} size="small" disabled={true}>
            <ArrowUpwardIcon />
          </Button>
        ) : (
          <Tooltip title="Move up">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() =>
                handleSort(
                  data[index],
                  data[indexPrevious],
                  data[index].order - 1,
                  data[index].order
                )
              }
            >
              <ArrowUpwardIcon />
            </Button>
          </Tooltip>
        )}
        {data[index].order >= data.length ? (
          <Button style={buttonsStyle} size="small" disabled={true}>
            <ArrowDownwardIcon />
          </Button>
        ) : (
          <Tooltip title="Move down">
            <Button
              style={buttonsStyle}
              size="small"
              onClick={() =>
                handleSort(
                  data[index],
                  data[indexNext],
                  data[index].order + 1,
                  data[index].order
                )
              }
            >
              <ArrowDownwardIcon />
            </Button>
          </Tooltip>
        )}
      </>
    );
  }

  const columns = [
    { dataField: "order", text: "Order", formatter: sortFormatter },
    { dataField: "name", text: "Full name" },
    { dataField: "active", text: "Active", formatter: booleanFormatter },
    { dataField: "createdAt", text: "Created at", formatter: dateFormatter },
    { dataField: "id", text: "", formatter: buttonFormatter },
  ];

  useEffect(() => {
    getCarrousels()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get carrousels.",
        });
      });
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Carrousels list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-carrousel")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"Are you sure you want to remove this carrousel?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCarrousel(carrouselId)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Carrousel removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove carrousel.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
