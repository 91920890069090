import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all medias
export const countMedias = () => {
  return authClient().get(`${API}/media/count`)
}

// Get all medias
export const getMedias = () => {
  return authClient().get(`${API}/media`)
}

// Get media by id
export const getMediaById = (id) => {
  return authClient().get(`${API}/media/${id}`)
}

// Get media by user id
export const getMediaByUserId = (id) => {
  return authClient().get(`${API}/media/user/${id}`)
}

// Delete media
export const deleteMedia = (id) => {
  return authClient().delete(`${API}/media/${id}`)
}

// Update media
export const updateMedia = async (id, media, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'media')
    media.filesURLs = filesURLs
  }
  return authClient().put(`${API}/media/${id}`, media)
}

// Save media
export const postMedia = async (media, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'media')
    media.filesURLs = filesURLs
  }
  return authClient().post(`${API}/media`, media)
}
