import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteProduct, getProductById, postProduct, updateProduct } from '../../../../api/product'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { SERVER_URL } from "../../../../api"
import { alertError, alertSuccess } from '../../../../utils/logger'
import { buttonsStyle } from '../../../components/tables/table'
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import Editor from '../../../components/editor/Editor'

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

/*const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
}*/

function getEmptyProduct() {
  return {
    name: {},
		description: {},
		imagesURLs: [],
    active: true
  }
}

export default function EditProductPage() {
  const [product, setProduct] = useState(getEmptyProduct())
	const [selectedImages, setSelectedImages] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
	const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const productId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!productId) {
      disableLoadingData()
      return
    }
    getProductById(productId).then(res => {
      if (res.status === 200) {
        setProduct(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get product.' })
      history.push('/products')
    })
  }, [productId, disableLoadingData, history])

  function saveProduct() {
    if (!productId) {
      if (!selectedImages) {
        alertError({ error: null, customMessage: 'Please upload at least one image.' })
        return
      }
      postProduct(product, selectedImages).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Product saved successfully.' })
          history.push('/products')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save product.' })
      })
    } else {
      updateProduct(productId, product, selectedImages).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/products')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!product[element]) product[element] = {}
      let newText = product[element]
      newText[lang] = event.target.value
      setProduct({ ...product, [element]: newText })
    } else setProduct({ ...product, [element]: event.target.value })
  }

  const handleChangeEditor = (element, lang, value) => {
    if (lang) {
      if (value === ' ') return
      if (!product[element]) product[element] = {}
      let newText = product[element]
      newText[lang] = value
      setProduct({ ...product, [element]: newText })
    } else setProduct({ ...product, [element]: value })
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`name`}
					label="Product name"
          value={(product.name && product.name[lang]) || ''}
          onChange={handleChange('name', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <Editor
          body={(product.description && product.description[lang]) || ''}
          setBody={new_body => handleChangeEditor('description', lang, new_body)}
          className='max-height'
          lang={lang}
          placeholder={'Enter product description here...'}
        />
      </>
    )
  }

  const handleDeleteImage = (index) => {
    // TODO: DELETE IMAGES FROM SERVER
    let newImages = [...product.imagesURLs]
    /*const deletedImage =*/ newImages.splice(index, 1)
    setProduct({ ...product, imagesURLs: newImages })
    if (selectedImages && selectedImages[index]) {
      let _selectedImages = {}
      for (const [key, value] of Object.entries(selectedImages)) {
        if (key !== index) {
          if (key > index) {
            _selectedImages[key - 1] = value
          } else _selectedImages[key] = value
        } 
      }
      if (!Object.keys(_selectedImages).length) _selectedImages = null
      setSelectedImages(_selectedImages)
    } /*else setDeletedImages(deletedImages ? deletedImages.concat(deletedImage) : [deletedImage])*/
  }

  const handleMoveImage = (index, newIndex) => {
    const aux = product.imagesURLs[index]
    let images = [...product.imagesURLs]
    images.splice(index, 1, images[newIndex])
    images.splice(newIndex, 1, aux)
    setProduct({ ...product, imagesURLs: images })
    if (selectedImages && (selectedImages[index] || selectedImages[newIndex])) {
      let _selectedImages = {}
      for (const [key, value] of Object.entries(selectedImages)) {
        if (key === index.toString()) _selectedImages[newIndex] = value
        else if (key === newIndex.toString()) _selectedImages[index] = value
        else _selectedImages[key] = value
      }
      setSelectedImages(_selectedImages)
    }
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit product'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
        </CardBody>
        <CardBody>
        {product.imagesURLs?.map((_, index) => {
            return (
              <>
                <label htmlFor={"upload-image" + index}>
                  <input
                    style={{ display: 'none' }}
                    id={"upload-image" + index}
                    name={"upload-image" + index}
                    type="file"
                    accept={'image/*'}
                    onChange={(e) => {
                      setSelectedImages({ ...selectedImages, [index]: e.target.files[0] })
                    }}
                  />
                  <Button
                    style={{ marginRight: '15px' }}
                    color="secondary"
                    component="span"
                    variant="outlined">
                    {((selectedImages && selectedImages[index]) || product.imagesURLs[index] !== '') ? 'Change image ' + (index + 1) : 'Upload image ' + (index + 1)}
                  </Button>
                </label>
                {((selectedImages && selectedImages[index]) || product.imagesURLs[index] !== '') &&
                  <>
                    <Tooltip title={'Preview image ' + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(index)}
                        style={buttonsStyle}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={'Preview image ' + (index + 1)}
                      open={openPreviewDialog === index}
                      setOpen={setOpenPreviewDialog}
                      src={(selectedImages && selectedImages[index]) ? URL.createObjectURL(selectedImages[index]) : `${SERVER_URL}/${product.imagesURLs[index]}`}
                    />
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteImage(index)}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move up">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() => handleMoveImage(index, index - 1)}>
                        <ArrowUpward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        size="small"
                        disabled={!(index < product.imagesURLs.length - 1 && ((selectedImages && selectedImages[index + 1]) || product.imagesURLs[index + 1] !== ''))}
                        style={{...buttonsStyle, marginRight: '1em' }}
                        onClick={() => handleMoveImage(index, index + 1)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <span>
                      {selectedImages && selectedImages[index] ? selectedImages[index]?.name : (product.imagesURLs[index] !== '' ? product.imagesURLs[index].split(/-(.*)/s)[1] : '')}
                    </span>
                    <br />
                  </>
                }
              </>
            )
          })}
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setProduct({ ...product, imagesURLs: product.imagesURLs.concat('') })}
          >
            Add image
          </Button>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={product.active} onChange={() => setProduct({ ...product, active: !product.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/products')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveProduct()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save product
      </Button>
      {productId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete product
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this product?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteProduct(productId).then(res => {
              if (res.status === 204 || res.status === 200) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Product deleted successfully' })
                history.push('/products')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete product.' })
            })
          }}
        />
      </>}
    </>
  );
}
