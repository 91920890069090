import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import NewsPage from "./modules/ContentManager/news/NewsPage";
import EditNewsPage from "./modules/ContentManager/news/EditNewsPage";
import ProductsPage from "./modules/ContentManager/products/ProductsPage";
import EditProductPage from "./modules/ContentManager/products/EditProductPage";
import PressPage from "./modules/ContentManager/press/PressPage";
import EditPressPage from "./modules/ContentManager/press/EditPressPage";
import GalleryPage from "./modules/ContentManager/gallery/GalleryPage";
import EditGalleryPage from "./modules/ContentManager/gallery/EditGalleryPage";
import MediaaperancePage from "./modules/ContentManager/mediaaperance/MediaaperancePage";
import EditMediaaperancePage from "./modules/ContentManager/mediaaperance/EditMediaaperancePage";
import MediaPage from "./modules/ContentManager/media/MediaPage";
import EditMediaPage from "./modules/ContentManager/media/EditMediaPage";
import ClientsPage from "./modules/ContentManager/clients/ClientsPage";
import EditClientsPage from "./modules/ContentManager/clients/EditClientsPage";
import LanguagesPage from "./modules/ContentManager/languages/LanguagesPage";
import EditLanguagesPage from "./modules/ContentManager/languages/EditLanguagesPage";
import ManagementTeamPage from "./modules/ContentManager/management-team/ManagementTeamPage";
import EditManagementTeamPage from "./modules/ContentManager/management-team/EditManagementTeamPage";
import TextsPage from "./modules/ContentManager/texts/TextsPage";
import EditTextsPage from "./modules/ContentManager/texts/EditTextsPage";
import ContactsPage from "./modules/ContentManager/contacts/ContactsPage";
import ViewContactsPage from "./modules/ContentManager/contacts/ViewContactsPage";
import CarrouselPage from "./modules/ContentManager/carrousel/CarrouselPage";
import EditCarrouselPage from "./modules/ContentManager/carrousel/EditCarrouselPage";

export default function BasePage() {
  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />

      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />

      {/* News */}
      <ContentRoute from="/news" component={NewsPage} />
      <ContentRoute from="/edit-news/:id?" component={EditNewsPage} />

      {/* Press */}
      <ContentRoute from="/presses" component={PressPage} />
      <ContentRoute from="/edit-press/:id?" component={EditPressPage} />

      {/* Gallery */}
      <ContentRoute from="/gallerys" component={GalleryPage} />
      <ContentRoute from="/edit-gallery/:id?" component={EditGalleryPage} />

      {/* Mediaaperance */}
      <ContentRoute from="/mediaaperances" component={MediaaperancePage} />
      <ContentRoute
        from="/edit-mediaaperance/:id?"
        component={EditMediaaperancePage}
      />

      {/* Media */}
      <ContentRoute from="/media" component={MediaPage} />
      <ContentRoute from="/edit-media/:id?" component={EditMediaPage} />

      {/* Products */}
      <ContentRoute from="/products" component={ProductsPage} />
      <ContentRoute from="/edit-product/:id?" component={EditProductPage} />

      {/* Clients */}
      <ContentRoute from="/clients" component={ClientsPage} />
      <ContentRoute from="/edit-client/:id?" component={EditClientsPage} />

      {/* Carrousel */}
      <ContentRoute from="/carrousel" component={CarrouselPage} />
      <ContentRoute from="/edit-carrousel/:id?" component={EditCarrouselPage} />

      {/* Languages */}
      <ContentRoute from="/languages" component={LanguagesPage} />
      <ContentRoute from="/edit-language/:id?" component={EditLanguagesPage} />

      {/* Management teams */}
      <ContentRoute from="/management-team" component={ManagementTeamPage} />
      <ContentRoute
        from="/edit-management-team/:id?"
        component={EditManagementTeamPage}
      />

      {/* Contacts */}
      <ContentRoute from="/contacts" component={ContactsPage} />
      <ContentRoute from="/view-contact/:id" component={ViewContactsPage} />

      {/* Texts */}
      <ContentRoute from="/texts" component={TextsPage} />
      <ContentRoute from="/edit-text/:id" component={EditTextsPage} />

      <Redirect to="/error" />
    </Switch>
  );
}
