import { authClient, API } from '../index'
import { uploadSingleFile } from "../files"

// Count all clients
export const countClients = () => {
  return authClient().get(`${API}/client/count`)
}

// Get all clients
export const getClients = () => {
  return authClient().get(`${API}/client`)
}

// Get client by id
export const getClientById = (id) => {
  return authClient().get(`${API}/client/${id}`)
}

// Delete client
export const deleteClient = (id) => {
  return authClient().delete(`${API}/client/${id}`)
}

// Update client
export const updateClient = async (id, client, logo) => {
    if (logo) {
      const response = await uploadSingleFile(logo, 'clients')
      client.logoURL = response.data.fileURL
    }
    return authClient().put(`${API}/client/${id}`, client)
}

// Save client
export const postClient = async (client, logo) => {
    if (logo) {
      const response = await uploadSingleFile(logo, 'clients')
      client.logoURL = response.data.fileURL
    }
    return authClient().post(`${API}/client`, client)
}

// Change order client
export const changeOrderClient = async (id, order) => {
  return authClient().put(`${API}/client/change-order/${id}?order=${order}`)
}
