import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Select,
  Checkbox,
  FormControlLabel,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCarrousel,
  getCarrouselById,
  postCarrousel,
  updateCarrousel,
} from "../../../../api/carrousel";
import { useSkeleton } from "../../../hooks/useSkeleton";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import { SERVER_URL } from "../../../../api";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { buttonsStyle } from "../../../components/tables/table";
import { Visibility } from "@material-ui/icons";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyCarrousel() {
  return {
    title: "",
    subtitle: "",
    imageURL: "",
    position: "",
    destiny: "",
    active: true,
    order: null,
  };
}

export default function EditCarrouselPage() {
  const [carrousel, setCarrousel] = useState(getEmptyCarrousel());
  const [selectedImage, setSelectedImage] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openPreviewImage, setOpenPreviewImage] = useState(false);
  const carrouselId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!carrouselId) {
      disableLoadingData();
      return;
    }
    getCarrouselById(carrouselId)
      .then((res) => {
        if (res.status === 200) {
          setCarrousel(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get carrousel." });
        history.push("/carrousel");
      });
  }, [carrouselId, disableLoadingData, history]);

  function saveCarrousel() {
    if (!carrouselId) {
      postCarrousel(carrousel, selectedImage)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Carrousel successfully created.",
            });
            history.push("/carrousel");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save carrousel.",
          });
        });
    } else {
      updateCarrousel(carrouselId, carrousel, selectedImage)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/carrousel");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === " ") return;
      if (!carrousel[element]) carrousel[element] = {};
      let newText = carrousel[element];
      newText[lang] = event.target.value;
      setCarrousel({ ...carrousel, [element]: newText });
    } else setCarrousel({ ...carrousel, [element]: event.target.value });
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <TextField
          id={`title`}
          label="Title"
          value={(carrousel.title && carrousel.title[lang]) || ""}
          onChange={handleChange("title", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          id={`subtitle`}
          label="Subtitle"
          value={(carrousel.subtitle && carrousel.subtitle[lang]) || ""}
          onChange={handleChange("subtitle", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id={`destiny`}
          label="Destiny"
          value={(carrousel.destiny && carrousel.destiny[lang]) || ""}
          onChange={handleChange("destiny", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
        />
      </>
    );
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit carrousel"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
            <label>
              Definir el desplazamiento de la imagen en el carrousel
            </label>
            <Select
              labelId="select-position"
              id="select-position"
              value={carrousel.position || ""}
              onChange={handleChange("position")}
              MenuProps={MenuProps}
              className="mx-5"
            >
              <MenuItem value={"center center"}>Center</MenuItem>
              <MenuItem value={"center right"}>Right</MenuItem>
              <MenuItem value={"center left"}>Left</MenuItem>
            </Select>
            <br />
            <br />
            <p>
              Recuerda que la imagen tiene que tener unas dimensiones de
              5912x2663 o 2956x1332. Es importante mantener esa relación de
              aspecto.
            </p>
            <label className="mt-4" htmlFor={"upload-image"}>
              <input
                style={{ display: "none" }}
                id={"upload-image"}
                name={"upload-image"}
                type="file"
                accept={"image/*"}
                onChange={(e) => {
                  setSelectedImage(e.target.files[0]);
                }}
              />
              <Button
                style={{ marginRight: "15px" }}
                color="secondary"
                component="span"
                variant="outlined"
              >
                {selectedImage || carrousel.imageURL !== ""
                  ? "Change image"
                  : "Upload image"}
              </Button>
            </label>
            {(selectedImage || carrousel.imageURL !== "") && (
              <>
                <Tooltip title={"Preview image"}>
                  <Button
                    size="small"
                    onClick={() => setOpenPreviewImage(true)}
                    style={buttonsStyle}
                  >
                    <Visibility />
                  </Button>
                </Tooltip>
                <PreviewDialog
                  title={"Preview image"}
                  open={openPreviewImage}
                  setOpen={setOpenPreviewImage}
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : `${SERVER_URL}/${carrousel.imageURL}`
                  }
                />
                <span style={{ marginLeft: "15px" }}>
                  {selectedImage
                    ? selectedImage?.name
                    : carrousel.imageURL !== ""
                    ? carrousel.imageURL.split(/-(.*)/s)[1]
                    : ""}
                </span>
              </>
            )}
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={carrousel.active}
                  onChange={() =>
                    setCarrousel({ ...carrousel, active: !carrousel.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/carrousel")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveCarrousel()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save carrousel
        </Button>
        {carrouselId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete carrousel
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this carrousel?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteCarrousel(carrouselId)
                  .then((res) => {
                    if (res.status === 204 || res.status === 200) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Carrousel deleted successfully",
                      });
                      history.push("/carrousel");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete carrousel.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
