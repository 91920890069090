import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all mediaaperances
export const countMediaaperances = () => {
  return authClient().get(`${API}/mediaaperance/count`)
}

// Get all mediaaperances
export const getMediaaperances = () => {
  return authClient().get(`${API}/mediaaperance`)
}

// Get mediaaperance by id
export const getMediaaperanceById = (id) => {
  return authClient().get(`${API}/mediaaperance/${id}`)
}

// Get mediaaperance by user id
export const getMediaaperanceByUserId = (id) => {
  return authClient().get(`${API}/mediaaperance/user/${id}`)
}

// Delete mediaaperance
export const deleteMediaaperance = (id) => {
  return authClient().delete(`${API}/mediaaperance/${id}`)
}

// Update mediaaperance
export const updateMediaaperance = async (id, mediaaperance, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'mediaaperance')
    mediaaperance.filesURLs = filesURLs
  }
  return authClient().put(`${API}/mediaaperance/${id}`, mediaaperance)
}

// Save mediaaperance
export const postMediaaperance = async (mediaaperance, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'mediaaperance')
    mediaaperance.filesURLs = filesURLs
  }
  return authClient().post(`${API}/mediaaperance`, mediaaperance)
}
