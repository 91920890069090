import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import Table, { dateFormatter, buttonsStyle, longTextFormatter, booleanFormatter } from '../../../components/tables/table'
import ConfirmDialog from '../../../components/dialogs/ConfirmDialog'
import { getMedias, deleteMedia } from '../../../../api/media'
import { Button, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alertError, alertSuccess } from '../../../../utils/logger'
import { useHistory } from 'react-router-dom'

function getData(medias) {
  let data = []
  for (let i = 0; i < medias.length; ++i) {
      const elem = {}
      elem.title = medias[i].title?.en
      elem.subtitle = medias[i].subtitle?.en || '---'
      elem.active = medias[i].active
      elem.createdAt = medias[i].createdAt
      elem.id = medias[i]._id
      data = data.concat(elem)
  }
  return data
}

export default function MediaPage() {
  const [data, setData] = useState([])
  const [mediaId, setMediaId] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const history = useHistory()

  function buttonFormatter(cell) {
    return (<>
      <Tooltip title="Edit">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => history.push('/edit-media/' + cell)}>
          <EditIcon/>
        </Button>
      </Tooltip>
      <Tooltip title="Delete">
        <Button
          style={buttonsStyle} size="small"
          onClick={() => {
            setMediaId(cell)
            setOpenConfirmDialog(true)
          }}>
          <DeleteIcon/>
        </Button>
      </Tooltip>
    </>)
  }

  const columns = [
    { dataField: 'title', text: 'Title', sort: true },
    { dataField: 'subtitle', text: 'Subtitle', sort: true },
    { dataField: 'active', text: 'Active', formatter: booleanFormatter, sort: true },
    { dataField: 'createdAt', text: 'Created at', formatter: dateFormatter, sort: true },
    { dataField: 'id', text: '', formatter: buttonFormatter }
  ]

  useEffect(() => {
    getMedias().then((res) => {
      if (res.status === 200) {
        setData(getData(res.data))
        setRefresh(false)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get medias.' })
    })
  }, [refresh])

  return (
    <>
      <Card>
        <CardHeader title='Medias list'>
          <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push('/edit-media')}
              >
                Add new
              </button>
            </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
          />
          <ConfirmDialog
            title={'Are you sure you want to remove this media?'}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteMedia(mediaId).then(res => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({  title: 'Deleted!', customMessage: 'Media removed successfully.' })
                  setRefresh(true)
                }
              }).catch(error => {
                alertError({ error: error, customMessage: 'Could not remove media.' })
              })
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
