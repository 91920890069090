/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import PeopleIcon from "@material-ui/icons/People";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import BallotIcon from "@material-ui/icons/Ballot";
import CategoryIcon from "@material-ui/icons/Category";
import LanguageIcon from "@material-ui/icons/Language";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import DescriptionIcon from "@material-ui/icons/Description";
import StoreIcon from "@material-ui/icons/Store";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import CollectionsIcon from "@material-ui/icons/Collections";
import TvIcon from "@material-ui/icons/Tv";
import PanoramaHorizontalIcon from "@material-ui/icons/PanoramaHorizontal";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">COMMUNICATION</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/news",
            false
          )} ${getMenuItemActive("/edit-news", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/news">
            <span className="menu-icon">
              <AnnouncementIcon />
            </span>
            <span className="menu-text">News</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/presses",
            false
          )} ${getMenuItemActive("/edit-press", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/presses">
            <span className="menu-icon">
              <BallotIcon />
            </span>
            <span className="menu-text">Press</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/mediaaperances",
            false
          )} ${getMenuItemActive("/edit-mediaaperances", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/mediaaperances">
            <span className="menu-icon">
              <OndemandVideoIcon />
            </span>
            <span className="menu-text">Apariciones en medios</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/gallerys",
            false
          )} ${getMenuItemActive("/edit-gallerys", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/gallerys">
            <span className="menu-icon">
              <CollectionsIcon />
            </span>
            <span className="menu-text">Galería de recursos</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">ENTITIES</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/clients",
            false
          )} ${getMenuItemActive("/edit-client", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/clients">
            <span className="menu-icon">
              <StoreIcon />
            </span>
            <span className="menu-text">Clients</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/carrousel",
            false
          )} ${getMenuItemActive("/edit-carrousel", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/carrousel">
            <span className="menu-icon">
              <PanoramaHorizontalIcon />
            </span>
            <span className="menu-text">Carrousels</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/products",
            false
          )} ${getMenuItemActive("/edit-product", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span className="menu-icon">
              <CategoryIcon />
            </span>
            <span className="menu-text">Products</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/management-team",
            false
          )} ${getMenuItemActive("/edit-management-team", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/management-team">
            <span className="menu-icon">
              <PeopleIcon />
            </span>
            <span className="menu-text">Management team</span>
          </NavLink>
        </li> */}
        <li className="menu-section">
          <h4 className="menu-text">USERS</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admins",
            false
          )} ${getMenuItemActive("/edit-admin", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/admins">
            <span className="menu-icon">
              <AssignmentIndIcon />
            </span>
            <span className="menu-text">Administrators</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">OTHERS</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/media",
            false
          )} ${getMenuItemActive("/edit-media", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/media">
            <span className="menu-icon">
              <TvIcon />
            </span>
            <span className="menu-text">Medios</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/languages",
            false
          )} ${getMenuItemActive("/edit-language", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/languages">
            <span className="menu-icon">
              <LanguageIcon />
            </span>
            <span className="menu-text">Languages</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/contacts",
            false
          )} ${getMenuItemActive("/view-contact", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/contacts">
            <span className="menu-icon">
              <AllInboxIcon />
            </span>
            <span className="menu-text">Contacts</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/texts",
            false
          )} ${getMenuItemActive("/edit-text", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/texts">
            <span className="menu-icon">
              <DescriptionIcon />
            </span>
            <span className="menu-text">Texts</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
