import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteGallery,
  getGalleryById,
  postGallery,
  updateGallery,
} from "../../../../api/gallery";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import PreviewDialog from "../../../components/dialogs/PreviewDialog";
import MultilanguageTabBlock from "../../../components/MultilanguageTabBlock";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { SERVER_URL } from "../../../../api";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { buttonsStyle } from "../../../components/tables/table";
import { ArrowUpward, ArrowDownward, Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import Editor from "../../../components/editor/Editor";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyGallery() {
  return {
    title: {},
    subtitle: {},
    filesURLs: [],
    active: true,
  };
}

export default function EditGalleryPage() {
  const [gallery, setGallery] = useState(getEmptyGallery());
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null);
  const galleryId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!galleryId) {
      disableLoadingData();
      return;
    }
    getGalleryById(galleryId)
      .then((res) => {
        if (res.status === 200) {
          setGallery(res.data);
          //Convert the urls to files
          setSelectedFiles(res.data.filesURLs ? res.data.filesURLs : null);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get gallery." });
        history.push("/gallerys");
      });
  }, [galleryId, disableLoadingData, history]);

  function saveGallery() {
    if (!galleryId) {
      postGallery(gallery, selectedFiles)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Gallery saved successfully.",
            });
            history.push("/gallerys");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save gallery.",
          });
        });
    } else {
      updateGallery(galleryId, gallery, selectedFiles)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/gallerys");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === " ") return;
      if (!gallery[element]) gallery[element] = {};
      let newText = gallery[element];
      newText[lang] = event.target.value;
      setGallery({ ...gallery, [element]: newText });
    } else setGallery({ ...gallery, [element]: event.target.value });
  };

  const handleChangeEditor = (element, lang, value) => {
    if (lang) {
      if (value === " ") return;
      if (!gallery[element]) gallery[element] = {};
      let newText = gallery[element];
      newText[lang] = value;
      setGallery({ ...gallery, [element]: newText });
    } else setGallery({ ...gallery, [element]: value });
  };

  // const handleDeleteFile = (index) => {
  //   // TODO: DELETE FILES FROM SERVER
  //   let newFiles = [...gallery.filesURLs];
  //   /*const deletedFile =*/ newFiles.splice(index, 1);
  //   setGallery({ ...gallery, filesURLs: newFiles });
  //   if (selectedFiles && selectedFiles[index]) {
  //     let _selectedFiles = {};
  //     for (const [key, value] of Object.entries(selectedFiles)) {
  //       if (key !== index) {
  //         if (key > index) {
  //           _selectedFiles[key - 1] = value;
  //         } else _selectedFiles[key] = value;
  //       }
  //     }
  //     if (!Object.keys(_selectedFiles).length) _selectedFiles = null;
  //     setSelectedFiles(_selectedFiles);
  //   } /*else setDeletedFiles(deletedFiles ? deletedFiles.concat(deletedFile) : [deletedFile])*/
  // };

  const handleDeleteFile = (index) => {
    let newFilesURLs = [...gallery.filesURLs];
    newFilesURLs.splice(index, 1);
    setGallery({ ...gallery, filesURLs: newFilesURLs });
    if (selectedFiles && selectedFiles.hasOwnProperty(index)) {
      const { [index]: _, ...newSelectedFiles } = selectedFiles;
      setSelectedFiles(newSelectedFiles);
    }
  };

  const handleMoveFile = (index, newIndex) => {
    const aux = gallery.filesURLs[index];
    let files = [...gallery.filesURLs];
    files.splice(index, 1, files[newIndex]);
    files.splice(newIndex, 1, aux);
    setGallery({ ...gallery, filesURLs: files });
    if (selectedFiles && (selectedFiles[index] || selectedFiles[newIndex])) {
      let _selectedFiles = {};
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key === index.toString()) _selectedFiles[newIndex] = value;
        else if (key === newIndex.toString()) _selectedFiles[index] = value;
        else _selectedFiles[key] = value;
      }
      setSelectedFiles(_selectedFiles);
    }
  };

  const renderMultilanguageTabContent = (lang) => {
    return (
      <>
        <TextField
          id={`title`}
          label="Title"
          value={(gallery.title && gallery.title[lang]) || ""}
          onChange={handleChange("title", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
          required
        />
        <TextField
          id={`subtitle`}
          label="Subtitle"
          value={(gallery.subtitle && gallery.subtitle[lang]) || ""}
          onChange={handleChange("subtitle", lang)}
          InputLabelProps={{
            shrink: true,
          }}
          margin="normal"
          variant="outlined"
        />
      </>
    );
  };

  useEffect(() => {
    console.log("DESPUES");
    console.log("SELECTED FILES: ", selectedFiles);
  }, [selectedFiles]);

  const handleSelectedFiles = (e, index) => {
    console.log("Antes");
    console.log("SELECTED FILES: ", selectedFiles);
    // `${SERVER_URL}/${gallery.filesURLs[index]}`
    // URL.createObjectURL(selectedFiles[index])
    let _selectedFiles = {};
    for (const [key, value] of Object.entries(selectedFiles || {})) {
      _selectedFiles[key] = value;
    }
    _selectedFiles[index] = e.target.files[0];
    setSelectedFiles(_selectedFiles);
  };

  // setSelectedFiles({
  //   ...selectedFiles,
  //   [index]: e.target.files[0],
  // });

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit gallery"></CardHeader>
          <CardBody>
            <MultilanguageTabBlock
              multilanguageTabContent={renderMultilanguageTabContent}
            />
          </CardBody>
          <CardBody>
            {gallery.filesURLs?.map((_, index) => {
              return (
                <div key={index}>
                  <label htmlFor={"upload-file" + index}>
                    <input
                      style={{ display: "none" }}
                      id={"upload-file" + index}
                      name={"upload-file" + index}
                      type="file"
                      onChange={(e) => {
                        handleSelectedFiles(e, index);
                      }}
                    />
                    <Button
                      style={{ marginRight: "15px" }}
                      color="secondary"
                      component="span"
                      variant="outlined"
                    >
                      {(selectedFiles && selectedFiles[index]) ||
                      gallery.filesURLs[index] !== ""
                        ? "Change file " + (index + 1)
                        : "Upload file " + (index + 1)}
                    </Button>
                  </label>
                  {((selectedFiles && selectedFiles[index]) ||
                    gallery.filesURLs[index] !== "") && (
                    <>
                      <Tooltip title={"Preview file " + (index + 1)}>
                        <Button
                          size="small"
                          onClick={() => setOpenPreviewDialog(index)}
                          style={buttonsStyle}
                        >
                          <Visibility />
                        </Button>
                      </Tooltip>
                      <PreviewDialog
                        title={"Preview file " + (index + 1)}
                        open={openPreviewDialog === index}
                        setOpen={setOpenPreviewDialog}
                        src={
                          selectedFiles && selectedFiles[index]
                            ? selectedFiles[index] instanceof File
                              ? URL.createObjectURL(selectedFiles[index])
                              : `${SERVER_URL}/${selectedFiles[index]}`
                            : `${SERVER_URL}/${gallery.filesURLs[index]}`
                        }
                      />
                      <Tooltip title="Delete">
                        <Button
                          size="small"
                          style={buttonsStyle}
                          onClick={() => handleDeleteFile(index)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Move up">
                        <Button
                          size="small"
                          style={buttonsStyle}
                          disabled={index === 0}
                          onClick={() => handleMoveFile(index, index - 1)}
                        >
                          <ArrowUpward />
                        </Button>
                      </Tooltip>
                      <Tooltip title="Move down">
                        <Button
                          size="small"
                          disabled={
                            !(
                              index < gallery.filesURLs.length - 1 &&
                              ((selectedFiles && selectedFiles[index + 1]) ||
                                gallery.filesURLs[index + 1] !== "")
                            )
                          }
                          style={{ ...buttonsStyle, marginRight: "1em" }}
                          onClick={() => handleMoveFile(index, index + 1)}
                        >
                          <ArrowDownward />
                        </Button>
                      </Tooltip>
                      <span>
                        {selectedFiles &&
                        selectedFiles[index] &&
                        selectedFiles[index] instanceof File
                          ? selectedFiles[index]?.name
                          : gallery.filesURLs[index] !== ""
                          ? gallery.filesURLs[index].split(/-(.*)/s)[1]
                          : ""}
                      </span>
                      <br />
                    </>
                  )}
                </div>
              );
            })}
            <br />
            <br />
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                setGallery({
                  ...gallery,
                  filesURLs: gallery.filesURLs.concat(""),
                })
              }
            >
              Add file
            </Button>
            <br />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={gallery.active}
                  onChange={() =>
                    setGallery({ ...gallery, active: !gallery.active })
                  }
                  name="checkActive"
                />
              }
              label="Active"
            />
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/gallerys")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveGallery()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save gallery
        </Button>
        {galleryId && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete gallery
              </Button>
            </MuiThemeProvider>
          </>
        )}
        <ConfirmDialog
          title={"Are you sure you want to delete this gallery?"}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteGallery(galleryId)
              .then((res) => {
                if (res.status === 204 || res.status === 200) {
                  alertSuccess({
                    title: "Deleted!",
                    customMessage: "Gallery deleted successfully",
                  });
                  history.push("/gallerys");
                }
              })
              .catch((error) => {
                alertError({
                  error: error,
                  customMessage: "Could not delete gallery.",
                });
              });
          }}
        />
      </>
    );
}
