import React, { useEffect, useState } from 'react';
import { alertError } from '../../utils/logger';
import { countProducts } from '../../api/product';
import { countNews } from '../../api/news';
import { countPresses } from '../../api/press';
import { countClients } from '../../api/client';
import { countLanguages } from '../../api/language';
import { countAdmins } from '../../api/user';
import { countManagementTeam } from '../../api/management-team';
import { countContacts } from '../../api/contact';
import { countTexts } from '../../api/text';
import { useHistory } from 'react-router-dom';

export function DashboardPage() {
	const [data, setData] = useState([]);
	const history = useHistory()

	useEffect(() => {
		async function fetchData() {
			try {
				const news = await countNews()		
				const presses = await countPresses()
				const products = await countProducts()
				const clients = await countClients()
				const languages = await countLanguages()
				const admins = await countAdmins()
				const managementTeams = await countManagementTeam()
				const contacts = await countContacts()
				const texts = await countTexts()

				setData({
					numNews: news.data,
					numPresses: presses.data,
					numProducts: products.data,
					numClients: clients.data,
					numAdmins: admins.data,
					numLanguages: languages.data,
					numManagementTeams: managementTeams.data,
					numContacts: contacts.data,
					numTexts: texts.data
				})
			} catch (err) {
				alertError({ error: err, customMessage: 'Could not get statistics.'})
			}
		}
		fetchData()
	}, []);

	return (
		<>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/news')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>News</h5>
								<div className='contentDash'>{data.numNews}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/presses')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Press</h5>
								<div className='contentDash'>{data.numPresses}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/clients')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Clients</h5>
								<div className='contentDash'>{data.numClients}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/management-team')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Management Team</h5>
								<div className='contentDash'>{data.numManagementTeams}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/products')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Products</h5>
								<div className='contentDash'>{data.numProducts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/admins')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Administrators</h5>
								<div className='contentDash'>{data.numAdmins}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/languages')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Languages</h5>
								<div className='contentDash'>{data.numLanguages}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/contacts')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Contacts</h5>
								<div className='contentDash'>{data.numContacts}</div>
							</div>
						</div>
					</div>
					<div className='col-12 col-md-4 text-center my-2' role='button' onClick={() => history.push('/texts')}>
						<div class='card'>
							<div class='card-body'>
								<h5 class='card-title'>Texts</h5>
								<div className='contentDash'>{data.numTexts}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
