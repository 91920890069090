import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Checkbox,
  FormControlLabel,
  Tooltip
} from "@material-ui/core"
import { useHistory, useParams } from "react-router-dom"
import { deleteManagementTeam, getManagementTeamById, postManagementTeam, updateManagementTeam } from "../../../../api/management-team"
import { useSkeleton } from "../../../hooks/useSkeleton"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import { SERVER_URL } from "../../../../api"
import { alertError, alertSuccess } from "../../../../utils/logger"
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import { buttonsStyle } from '../../../components/tables/table'
import { Visibility } from '@material-ui/icons'

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#F64E60'
    }
  }
})

function getEmptyManagementTeam() {
  return {
    fullName: '',
    position: '',
    imageURL: '',
    active: true,
    order: null
  }
}


export default function EditManagementTeamPage() {
  const [managementTeam, setManagementTeam] = useState(getEmptyManagementTeam())
  const [selectedImage, setSelectedImage] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewImage, setOpenPreviewImage] = useState(false)
  const managementTeamId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    if (!managementTeamId) {
      disableLoadingData()
      return
    }
    getManagementTeamById(managementTeamId).then(res => {
      if (res.status === 200) {
        setManagementTeam(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get management team member.' })
      history.push('/management-team')
    })
  }, [managementTeamId, disableLoadingData, history])

  function saveManagementTeam() {
    if (!managementTeamId) {
      postManagementTeam(managementTeam, selectedImage).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Management team member successfully created.' })
          history.push('/management-team')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save management team member.' })
      })
    } else {
      updateManagementTeam(managementTeamId, managementTeam, selectedImage).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/management-team')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element) => (event) => {
    setManagementTeam({ ...managementTeam, [element]: event.target.value })
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit management team member'>
        </CardHeader>
        <CardBody>
          <TextField
            id={`fullName`}
            label="Full name"
            value={managementTeam.fullName || ''}
            onChange={handleChange('fullName')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            id={`position`}
            label="Position"
            value={managementTeam.position || ''}
            onChange={handleChange('position')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
            required
          />
          {managementTeam._id && <TextField
            id={`order`}
            label="Order"
            value={managementTeam.order || ''}
            onChange={handleChange('order')}
            InputLabelProps={{
              shrink: true
            }}
            type="number"
            margin="normal"
            variant="outlined"
            required
            disabled
          />}
          <label className='mt-4' htmlFor={"upload-image"}>
            <input
              style={{ display: 'none' }}
              id={"upload-image"}
              name={"upload-image"}
              type="file"
              accept={'image/*'}
              onChange={(e) => {
                setSelectedImage(e.target.files[0])
              }}
            />
            <Button
              style={{ marginRight: '15px' }}
              color="secondary"
              component="span"
              variant="outlined">
              {((selectedImage || managementTeam.imageURL !== '') ? 'Change image' : 'Upload image')}
            </Button>
          </label>
          {(selectedImage || managementTeam.imageURL !== '') &&
            <>
              <Tooltip title={'Preview image '}>
                <Button
                  size="small"
                  onClick={() => setOpenPreviewImage(true)}
                  style={buttonsStyle}>
                  <Visibility/>
                </Button>
              </Tooltip>
              <PreviewDialog
                title={'Preview image'}
                open={openPreviewImage}
                setOpen={setOpenPreviewImage}
                src={(selectedImage) ? URL.createObjectURL(selectedImage) : `${SERVER_URL}/${managementTeam.imageURL}`}
              />
              <span style={{ marginLeft: '15px' }}>
                {selectedImage ? selectedImage?.name : (managementTeam.imageURL !== '' ? managementTeam.imageURL.split(/-(.*)/s)[1] : '')}
              </span>
            </>
					}          
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={managementTeam.active} onChange={() => setManagementTeam({ ...managementTeam, active: !managementTeam.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/management-team')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveManagementTeam()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save member
      </Button>
      {managementTeamId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete member
          </Button>
        </MuiThemeProvider>

        <ConfirmDialog
          title={'Are you sure you want to delete this management team member?'}
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onConfirm={() => {
            deleteManagementTeam(managementTeamId).then(res => {
              if (res.status === 204 || res.status === 200) {
                alertSuccess({ title: 'Deleted!', customMessage: 'Management team member deleted successfully' })
                history.push('/management-team')
              }
            }).catch(error => {
              alertError({ error: error, customMessage: 'Could not delete management team member.' })
            })
          }}
        />
      </>}
    </>
  );
}
