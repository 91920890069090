import React, { useState, useEffect } from 'react'
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import {
	Button,
	TextField,
	MuiThemeProvider,
	createMuiTheme,
  Checkbox,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip
} from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { deleteMediaaperance, getMediaaperanceById, postMediaaperance, updateMediaaperance } from '../../../../api/mediaaperance'
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog"
import PreviewDialog from "../../../components/dialogs/PreviewDialog"
import MultilanguageTabBlock from '../../../components/MultilanguageTabBlock'
import { useSkeleton } from '../../../hooks/useSkeleton'
import { SERVER_URL } from "../../../../api"
import { alertError, alertSuccess } from '../../../../utils/logger'
import { buttonsStyle } from '../../../components/tables/table'
import { ArrowUpward, ArrowDownward, Visibility } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import { getMedias } from "../../../../api/media"

// Create theme for delete button (red)
const theme = createMuiTheme({
	palette: {
		secondary: {
			main: '#F64E60'
		}
  }
})

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyMediaaperance() {
  return {
    title: {},
    subtitle: {},
    filesURLs: [],
    link: '',
    media: '',
    active: true,
    date: null
  }
}

export default function EditMediaaperancePage() {
  const [mediaaperance, setMediaaperance] = useState(getEmptyMediaaperance())
  const [medias, setMedias] = useState(null)
	const [selectedFiles, setSelectedFiles] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openPreviewDialog, setOpenPreviewDialog] = useState(null)
  const mediaaperanceId = useParams().id
  const history = useHistory()

  const { isLoading: isLoadingData, disableLoading: disableLoadingData, ContentSkeleton } = useSkeleton()

  useEffect(() => {
    getMedias().then(res => {
      if (res.status === 200) {
        setMedias(res.data)
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get medias.' })
      history.push('/mediaaperances')
    })
  }, [disableLoadingData, history])

  useEffect(() => {
    if (!mediaaperanceId) {
      disableLoadingData()
      return
    }
    getMediaaperanceById(mediaaperanceId).then(res => {
      if (res.status === 200) {
        setMediaaperance(res.data)
        disableLoadingData()
      }
    }).catch(error => {
      alertError({ error: error, customMessage: 'Could not get mediaaperance.' })
      history.push('/mediaaperances')
    })
  }, [mediaaperanceId, disableLoadingData, history])

  function saveMediaaperance() {
    if (!mediaaperanceId) {
      postMediaaperance(mediaaperance, selectedFiles).then(res => {
        if (res.status === 201) {
          alertSuccess({ title: 'Saved!', customMessage: 'Mediaaperance saved successfully.' })
          history.push('/mediaaperances')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save mediaaperance.' })
      })
    } else {
      updateMediaaperance(mediaaperanceId, mediaaperance, selectedFiles).then(res => {
        if (res.status === 200) {
          alertSuccess({ title: 'Saved!', customMessage: 'Changes successfully saved.' })
          history.push('/mediaaperances')
        }
      }).catch(error => {
        alertError({ error: error, customMessage: 'Could not save changes.' })
      })
    }
  }

  const handleChange = (element, lang) => (event) => {
    if (lang) {
      if (event.target.value === ' ') return
      if (!mediaaperance[element]) mediaaperance[element] = {}
      let newText = mediaaperance[element]
      newText[lang] = event.target.value
      setMediaaperance({ ...mediaaperance, [element]: newText })
    } else setMediaaperance({ ...mediaaperance, [element]: event.target.value })
  }

  const handleChangeEditor = (element, lang, value) => {
    if (lang) {
      if (value === ' ') return
      if (!mediaaperance[element]) mediaaperance[element] = {}
      let newText = mediaaperance[element]
      newText[lang] = value
      setMediaaperance({ ...mediaaperance, [element]: newText })
    } else setMediaaperance({ ...mediaaperance, [element]: value })
  }

  const handleDeleteFile = (index) => {
    // TODO: DELETE FILES FROM SERVER
    let newFiles = [...mediaaperance.filesURLs]
    /*const deletedFile =*/ newFiles.splice(index, 1)
    setMediaaperance({ ...mediaaperance, filesURLs: newFiles })
    if (selectedFiles && selectedFiles[index]) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key !== index) {
          if (key > index) {
            _selectedFiles[key - 1] = value
          } else _selectedFiles[key] = value
        } 
      }
      if (!Object.keys(_selectedFiles).length) _selectedFiles = null
      setSelectedFiles(_selectedFiles)
    } /*else setDeletedFiles(deletedFiles ? deletedFiles.concat(deletedFile) : [deletedFile])*/
  }

  const handleMoveFile = (index, newIndex) => {
    const aux = mediaaperance.filesURLs[index]
    let files = [...mediaaperance.filesURLs]
    files.splice(index, 1, files[newIndex])
    files.splice(newIndex, 1, aux)
    setMediaaperance({ ...mediaaperance, filesURLs: files })
    if (selectedFiles && (selectedFiles[index] || selectedFiles[newIndex])) {
      let _selectedFiles = {}
      for (const [key, value] of Object.entries(selectedFiles)) {
        if (key === index.toString()) _selectedFiles[newIndex] = value
        else if (key === newIndex.toString()) _selectedFiles[index] = value
        else _selectedFiles[key] = value
      }
      setSelectedFiles(_selectedFiles)
    }
  }

  const renderMultilanguageTabContent = lang => {
    return (
      <>
        <TextField
          id={`title`}
					label="Title"
          value={(mediaaperance.title && mediaaperance.title[lang]) || ''}
          onChange={handleChange('title', lang)}
          InputLabelProps={{
            shrink: true
          }}
          margin="normal"
          variant="outlined"
          required
        />
				<TextField
					id={`subtitle`}
					label="Subtitle"
					value={(mediaaperance.subtitle && mediaaperance.subtitle[lang]) || ''}
					onChange={handleChange('subtitle', lang)}
					InputLabelProps={{
						shrink: true
					}}
					margin="normal"
					variant="outlined"
				/>
      </>
    )
  }

  if (isLoadingData)
    return <ContentSkeleton />
  else return (
    <>
      <Card>
        <CardHeader title='Edit mediaaperance'>
        </CardHeader>
        <CardBody>
          <MultilanguageTabBlock
						multilanguageTabContent = {renderMultilanguageTabContent}
					/>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={mediaaperance.media || ''}
            onChange={handleChange('media')}
            MenuProps={MenuProps}
          >
            {medias?.map((option) => (
              <MenuItem key={option._id} value={option._id}>{option.title.es}</MenuItem>
            ))}
          </Select>
          <TextField
            id={`link`}
            label="Link"
            value={mediaaperance.link}
            onChange={handleChange('link')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
          <TextField
            id="date"
            label="Date"
            type="date"
            value={mediaaperance.date && mediaaperance.date.includes('T') ? mediaaperance.date.split('T')[0] : mediaaperance.date}
            onChange={handleChange('date')}
            InputLabelProps={{
              shrink: true
            }}
            margin="normal"
            variant="outlined"
          />
        </CardBody>
        <CardBody>
          {mediaaperance.filesURLs?.map((_, index) => {
            return (
              <>
                <label htmlFor={"upload-file" + index}>
                  <input
                    style={{ display: 'none' }}
                    id={"upload-file" + index}
                    name={"upload-file" + index}
                    type="file"
                    onChange={(e) => {
                      setSelectedFiles({ ...selectedFiles, [index]: e.target.files[0] })
                    }}
                  />
                  <Button
                    style={{ marginRight: '15px' }}
                    color="secondary"
                    component="span"
                    variant="outlined">
                    {((selectedFiles && selectedFiles[index]) || mediaaperance.filesURLs[index] !== '') ? 'Change file ' + (index + 1) : 'Upload file ' + (index + 1)}
                  </Button>
                </label>
                {((selectedFiles && selectedFiles[index]) || mediaaperance.filesURLs[index] !== '') &&
                  <>
                    <Tooltip title={'Preview file ' + (index + 1)}>
                      <Button
                        size="small"
                        onClick={() => setOpenPreviewDialog(index)}
                        style={buttonsStyle}>
                        <Visibility/>
                      </Button>
                    </Tooltip>
                    <PreviewDialog
                      title={'Preview file ' + (index + 1)}
                      open={openPreviewDialog === index}
                      setOpen={setOpenPreviewDialog}
                      src={(selectedFiles && selectedFiles[index]) ? URL.createObjectURL(selectedFiles[index]) : `${SERVER_URL}/${mediaaperance.filesURLs[index]}`}
                    />
                    <Tooltip title="Delete">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        onClick={() => handleDeleteFile(index)}>
                        <DeleteIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move up">
                      <Button
                        size="small"
                        style={buttonsStyle}
                        disabled={index === 0}
                        onClick={() => handleMoveFile(index, index - 1)}>
                        <ArrowUpward/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Move down">
                      <Button
                        size="small"
                        disabled={!(index < mediaaperance.filesURLs.length - 1 && ((selectedFiles && selectedFiles[index + 1]) || mediaaperance.filesURLs[index + 1] !== ''))}
                        style={{...buttonsStyle, marginRight: '1em' }}
                        onClick={() => handleMoveFile(index, index + 1)}>
                        <ArrowDownward/>
                      </Button>
                    </Tooltip>
                    <span>
                      {selectedFiles && selectedFiles[index] ? selectedFiles[index]?.name : (mediaaperance.filesURLs[index] !== '' ? mediaaperance.filesURLs[index].split(/-(.*)/s)[1] : '')}
                    </span>
                    <br />
                  </>
                }
              </>
            )
          })}
          <br />
          <br />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setMediaaperance({ ...mediaaperance, filesURLs: mediaaperance.filesURLs.concat('') })}
          >
            Add file
          </Button>
          <div style={{'font-size':'13px', 'margin-top':'5px'}}>Screenshot size should be approximately 1369px x 912px</div>
          <br />
          <br />
          <FormControlLabel
            control={<Checkbox checked={mediaaperance.active} onChange={() => setMediaaperance({ ...mediaaperance, active: !mediaaperance.active })} name="checkActive" />}
            label="Active"
          />
        </CardBody>
      </Card>
      <Button
        onClick={() => history.push('/mediaaperances')}
        variant="outlined"
        style={{ marginRight: '20px' }}>
        Back
      </Button>
      <Button
        onClick={() => saveMediaaperance()}
        variant="outlined"
        color="primary"
        style={{ marginRight: '20px' }}>
        Save mediaaperance
      </Button>
      {mediaaperanceId && <>
        <MuiThemeProvider theme={theme}>
          <Button
            onClick={() => setOpenConfirmDialog(true)}
            variant="outlined"
            color="secondary">
            Delete mediaaperance
          </Button>
        </MuiThemeProvider>
      </>}
      <ConfirmDialog
        title={'Are you sure you want to delete this mediaaperance?'}
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        onConfirm={() => {
          deleteMediaaperance(mediaaperanceId).then(res => {
            if (res.status === 204 || res.status === 200) {
              alertSuccess({ title: 'Deleted!', customMessage: 'Mediaaperance deleted successfully' })
              history.push('/mediaaperances')
            }
          }).catch(error => {
            alertError({ error: error, customMessage: 'Could not delete mediaaperance.' })
          })
        }}
      />
    </>
  );
}
