import { authClient, API } from '../index'
import { uploadFilesGetLinks } from "../files"

// Count all news
export const countNews = () => {
  return authClient().get(`${API}/news/count`)
}

// Get all news
export const getNews = () => {
  return authClient().get(`${API}/news`)
}

// Get news by id
export const getNewsById = (id) => {
  return authClient().get(`${API}/news/${id}`)
}

// Get news by user id
export const getNewsByUserId = (id) => {
  return authClient().get(`${API}/news/user/${id}`)
}

// Delete news
export const deleteNews = (id) => {
  return authClient().delete(`${API}/news/${id}`)
}

// Update news
export const updateNews = async (id, news, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'news')
    news.filesURLs = filesURLs
  }
  return authClient().put(`${API}/news/${id}`, news)
}

// Save news
export const postNews = async (news, files) => {
  if (files) {
    const filesURLs = await uploadFilesGetLinks(files, 'news')
    news.filesURLs = filesURLs
  }
  return authClient().post(`${API}/news`, news)
}
